// Type for TenantTheme
interface TenantTheme {
  tenantId: string;
  brandName: string;
  brandLegalName: string;
  brandLegalText: string;
  companyWebsiteUrl: string;
  customerSupportUrl: string;
  customerSupportEmail: string;
  customerSupportPhone: string;
  headerLogoImageUrl: string;
  headerLogoImageAlt: string;
  brandLogoImageUrl: string;
  brandLogoImageAlt: string;
  brandColorPrimary: string;
  brandColorSecondary: string;
  brandColorTertiary: string;
  brandColorButton: string;
  brandColorButtonHover: string;
}

// useTenantTheme composable
export default function () {
  // Uses nuxt 3's useState composable to enable SSR-friendly tenant theme state.
  // Fetches tenant theme from the server to initialise. This will happen during SSR.
  return useState<TenantTheme>('tenant-theme', () => {
    // Fetch tenant theme from server
    // TODO: Fetch tenant theme from the server and update state.

    // Temporary hack to set theme depending on if we have a tenant ID
    // Route needed for temporary hack..
    const route = useRoute()

    // Default Theme
    let tenantTheme: TenantTheme = {
      tenantId: 'wetix',
      brandName: 'WeTix',
      brandLegalName: 'WeTix Ltd',
      brandLegalText:
        'WeTix Ltd is a limited company registered in the United Kingdom under registration #14163918',
      companyWebsiteUrl: 'https://wetix.events',
      customerSupportUrl: 'https://wetix.support',
      customerSupportEmail: 'help@wetix.co',
      customerSupportPhone: '+44 118 230 1111',
      headerLogoImageUrl:
        'https://imagedelivery.net/kCXBEBYe-fVFQQ06UGmg1w/717d769e-6039-4e56-a395-1ae084030c00/public',
      headerLogoImageAlt: 'WeTix',
      brandLogoImageUrl:
        'https://imagedelivery.net/kCXBEBYe-fVFQQ06UGmg1w/717d769e-6039-4e56-a395-1ae084030c00/public',
      brandLogoImageAlt: 'WeTix',
      brandColorPrimary: '#4F46E5',
      brandColorSecondary: '#6B7280',
      brandColorTertiary: '#F51030',
      brandColorButton: '#4F46E5',
      brandColorButtonHover: '#4338CA'
    }

    // Hack to override theme for specific tenant IDs
    console.log(route?.params)
    if (route?.params?.tenant) {
      if (route.params.tenant === 'tunes') {
        tenantTheme = {
          tenantId: 'tunes',
          brandName: 'Tunes Events',
          brandLegalName: 'Tunes in the Dunes Ltd',
          brandLegalText:
            'Tunes in the Dunes Ltd is a limited company registered in the United Kingdom under registration #14163918',
          companyWebsiteUrl: 'https://tunes.events',
          customerSupportUrl: 'https://support.tunesfestivals.com',
          customerSupportEmail: 'help@tunesfestivals.com',
          customerSupportPhone: '+44 1872 30366',
          headerLogoImageUrl:
            'https://assets-cdn.wetix.net/images/tunes-logo-small.png',
          headerLogoImageAlt: 'TUNES',
          brandLogoImageUrl:
            'https://assets-cdn.wetix.net/images/tunes-logo-small.png',
          brandLogoImageAlt: 'Tunes',
          brandColorPrimary: '#22843B',
          brandColorSecondary: '#039BE5',
          brandColorTertiary: '#F51030',
          brandColorButton: '#22843B',
          brandColorButtonHover: '#30BB54'
        }
      }
    }

    if (route?.params?.tenant) {
      if (route.params.tenant === 'ffxiv') {
        tenantTheme = {
          tenantId: 'ffxiv',
          brandName: 'Final Fantasy XIV',
          brandLegalName: 'Square Enix Co., Ltd.',
          brandLegalText: '© SQUARE ENIX CO., LTD. All Rights Reserved.',
          companyWebsiteUrl: 'https://fanfest.finalfantasyxiv.com/',
          customerSupportUrl: 'https://wetix.page/sqenix-support',
          customerSupportEmail: 'help@wetix.co',
          customerSupportPhone: '+1000000000',
          headerLogoImageUrl:
            'https://imagedelivery.net/kCXBEBYe-fVFQQ06UGmg1w/c690f538-bfd1-4e15-c933-ee1503b9e800/wetixtenantheaderlogo',
          headerLogoImageAlt: 'Final Fantasy XIV Fan Festival',
          brandLogoImageUrl:
            'https://imagedelivery.net/kCXBEBYe-fVFQQ06UGmg1w/c690f538-bfd1-4e15-c933-ee1503b9e800/wetixtenantheaderlogo',
          brandLogoImageAlt: 'Final Fantasy XIV Fan Festival',
          brandColorPrimary: '#E60D33',
          brandColorSecondary: '#FDC200',
          brandColorTertiary: '#F51030',
          brandColorButton: '#E60D33',
          brandColorButtonHover: '#30BB54'
        }
      }
    }

    if (route?.params?.tenant) {
      if (route.params.tenant === 'lawrances') {
        tenantTheme = {
          tenantId: 'lawrances',
          brandName: 'Lawrances Bakery & Bar',
          brandLegalName: 'Lawrances Bakery & Bar ',
          brandLegalText: '© WeTix Ltd. All Rights Reserved.',
          companyWebsiteUrl: '#',
          customerSupportUrl: '#',
          customerSupportEmail: 'help@wetix.co',
          customerSupportPhone: '+1000000000',
          headerLogoImageUrl:
            'https://imagedelivery.net/_T9XbrTSKaaBQ_3RCIlZVg/78c96246-0390-4330-6baf-bb7a503bc800/160pxwide',
          headerLogoImageAlt: 'Lawrances Bakery & Bar',
          brandLogoImageUrl:
            'https://imagedelivery.net/_T9XbrTSKaaBQ_3RCIlZVg/78c96246-0390-4330-6baf-bb7a503bc800/160pxwide',
          brandLogoImageAlt: 'Lawrances Bakery & Bar',
          brandColorPrimary: '#094d07',
          brandColorSecondary: '#FDC200',
          brandColorTertiary: '#F51030',
          brandColorButton: '#094d07',
          brandColorButtonHover: '#30BB54'
        }
      }
    }

    if (route?.params?.tenant) {
      if (route.params.tenant === 'sorare-sevens') {
        tenantTheme = {
          tenantId: 'sorare-sevens',
          brandName: 'Sorare Sevens',
          brandLegalName: 'Sorare Sevens',
          brandLegalText: '© WeTix Ltd. All Rights Reserved.',
          companyWebsiteUrl: '#',
          customerSupportUrl: '#',
          customerSupportEmail: 'help@wetix.co',
          customerSupportPhone: '+1000000000',
          headerLogoImageUrl:
            'https://wetix.net/cdn-cgi/imagedelivery/_T9XbrTSKaaBQ_3RCIlZVg/78dc7fd5-90ce-42c4-964f-8f8b51213400/public',
          headerLogoImageAlt: 'Sorare Sevens',
          brandLogoImageUrl:
            'https://wetix.net/cdn-cgi/imagedelivery/_T9XbrTSKaaBQ_3RCIlZVg/78dc7fd5-90ce-42c4-964f-8f8b51213400/public',
          brandLogoImageAlt: 'Sorare Sevens',
          brandColorPrimary: '#01431D',
          brandColorSecondary: '#FDC200',
          brandColorTertiary: '#F51030',
          brandColorButton: '#01431D',
          brandColorButtonHover: '#30BB54'
        }
      }
    }

    return tenantTheme
  })
}
